import { useState, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { fetchFromAPI } from '../utils/fetchFromAPI'
// import Videos from './Videos'
import { Videos} from './'
// Since feed.jsx is already in components folder, we don't need to import them from './components'
import { useParams } from 'react-router-dom'

const SearchFeed = () => {
  
  const [videos, setVideos] = useState([])
  const  {searchTerm}  = useParams()

  useEffect(() => {
    fetchFromAPI(`search?part=snippet&q=${searchTerm}`)
      .then((data) => setVideos(data.items))
  }, [searchTerm])

  return (
    <Box p={2} sx={{ overflowY: 'auto', height: '90vh', flex: 2}}>
      <Typography variant='h4' fontWeight='bold' mb={2} sx={{ color: 'white' }}>
        Search Results for: <span style={{ color: '#FC1503'}}>{searchTerm}</span> Videos
      </Typography>
      <Videos videos={videos} />
    </Box>
  )
}

export default SearchFeed